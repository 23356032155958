
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  alertController,
} from "@ionic/vue";

import { useRouter } from "vue-router";

import { dbUser, dbCustomers } from "../services/localbase";

export default {
  name: "Tab4",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
  },
  setup() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const userImage = `https://crm.sicurity.it/imgn/1/100/100/uploads/${user.users_avatar}`;
    const router = useRouter();
    //console.log(user);

    /**
     *
     * Set user avatar image
     *
     */
    function setImage(user) {
      if (user.users_avatar) {
        return `https://crm.sicurity.it/imgn/1/100/100/uploads/${user.users_avatar}`;
      } else {
        return `https://crm.sicurity.it/images/user.png`;
      }
    }

    /**
     *
     * Delete localbase CLIENTI and USER database
     *
     */
    async function deleteDb() {
      const deleteCustomer = await dbCustomers.collection("clienti").delete();
      const deleteUser = await dbUser.collection("user").delete();
    }

    /**
     *
     * Delete Localbase database and clear localStorage
     * Redirect user to Login page
     *
     */
    function logOut() {
      deleteDb().then(() => {
        localStorage.clear();
        router.replace("/login");
      });
    }

    /*
     *
     * Alert to confirm logout action
     *
     */
    async function handleLogout() {
      const alert = await alertController.create({
        header: "Conferma",
        message: "Sei sicuro di voler uscire dal tuo account?",
        backdropDismiss: false,
        buttons: [
          {
            text: "Annulla",
            role: "cancel",
            handler: () => {
              //console.log("CHIUSUTRA ALERT RICHIESTA RECUPERO PASSWORD...");
            },
          },
          {
            text: "Conferma",
            handler: () => {
              //Call logout function (delete localbasedb and redirect to login page)
              logOut();
            },
          },
        ],
      });
      return alert.present();
    }

    /**
     *
     * Force app update
     *
     */
    function updateApp() {
      window.location.reload(true);
    }

    return {
      user,
      setImage,
      userImage,
      handleLogout,
      updateApp,
    };
  },
};
